import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersService } from './users.service';

const SERVICES = [UsersService];

@NgModule({
  imports: [CommonModule],
  providers: [...SERVICES],
})
export class MockDataModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: MockDataModule,
      providers: [...SERVICES],
    };
  }
}
