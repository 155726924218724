import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Registry } from '../models/registry';
import { Translation } from '../models/translation';

@Injectable()
export class RegistryService {
  constructor(private http: HttpClient) {}

  protected _registryData: any;
  private _registryDataPromise: Promise<any>;

  getCurrentRegistry(): Promise<any> {
    if (this._registryData) return Promise.resolve(this._registryData);

    if (this._registryDataPromise) return this._registryDataPromise;

    this._registryDataPromise = this.http
      .get<Registry>(`${environment.baseApiUrl}api/registries/current`)
      .toPromise();
    this._registryDataPromise.then(data => {
      this._registryData = data;
    });
    return this._registryDataPromise;
  }

  regenerateKeys(id: number): Observable<any> {
    return this.http.post<any>(
      `${environment.baseApiUrl}api/registries/regenerate-keys/${id}`,
      id,
    );
  }

  getRegistries(pageData): Observable<any> {
    const skip = `$skip=${pageData.page}`;
    const top = `$top=${pageData.size}`;
    let orderBy: string;
    if (pageData.sortField === 'LocationContent') {
      pageData.sortField = 'Location/country';
    } else if (pageData.sortField === 'LanguageContent') {
      pageData.sortField = 'Language/name';
    } else if (pageData.sortField === 'IsCurrent') {
      pageData.sortField = 'is_current';
    } else if (pageData.sortField === 'Code') {
      pageData.sortField = 'code';
    } else if (pageData.sortField === 'Active') {
      pageData.sortField = 'active';
    } else if (pageData.sortField === 'Name') {
      pageData.sortField = 'name';
    }

    if (pageData.sortField !== undefined || pageData.sortField !== null) {
      orderBy = `$orderby=${pageData.sortField}${pageData.sortOrder}`;
    }
    return this.http
      .get<any>(
        environment.baseApiUrl + `api/registries?${skip}&${top}${orderBy}`,
      )
      .pipe(
        map(res => res),
        shareReplay(),
      );
  }

  getRegistryById(id): Observable<any> {
    return this.http
      .get<any>(environment.baseApiUrl + `api/registries/${id}`)
      .pipe(
        map(res => res),
        shareReplay(),
      );
  }

  getRegistryTimezones(): Observable<any> {
    return this.http.get<any>(
      `${environment.baseApiUrl}api/registries/timezones`,
    );
  }

  getAvailableLocations(): Observable<any> {
    return this.http.get<any>(
      `${environment.baseApiUrl}api/registries/available-locations`,
    );
  }

  // getCurrentRegistry(): Observable<any> {
  //   return this.http
  //     .get<any>(environment.baseApiUrl + `api/registries/current`)
  //     .pipe(
  //       map(res => res),
  //       shareReplay(),
  //     );
  // }

  dataSystemConfigurationAddRegistry(registry): Observable<any> {
    return this.http.post<boolean>(
      environment.baseApiUrl + `api/registries/`,
      registry,
    );
  }

  dataSystemConfigurationEditRegistry(id, registry): Observable<any> {
    return this.http.put<boolean>(
      environment.baseApiUrl + `api/registries/${id}`,
      registry,
    );
  }

  addLanguage(id, data): Observable<any> {
    return this.http.put<any>(
      environment.baseApiUrl + 'api/registries/' + id,
      data,
    );
  }

  addTranslation(id, data: Translation): Observable<any> {
    return this.http.post<any>(
      `${environment.baseApiUrl}/api/registries/translation/${id}`,
      data,
    );
  }
}
