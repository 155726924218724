import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent implements OnInit {
  title: string = 'default title';
  body: string = 'default body';
  buttonLabel: string = 'confirm';
  size: string = 'small';

  constructor(protected ref: NbDialogRef<MessageDialogComponent>) {}

  ngOnInit() {}

  close(value: boolean) {
    this.ref.close(value);
  }
}
