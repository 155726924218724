import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Translation } from '../models/translation';

@Injectable()
export class MasterLookupService {
  constructor(private http: HttpClient) {}

  dataMasterLookupTypes(pageData): Observable<any> {
    let filter: string = '';
    const skip = `$skip=${pageData.page}`;
    const top = `$top=${pageData.size}`;
    let orderBy: string;
    if (pageData.sortField !== undefined || pageData.sortField !== null) {
      orderBy = `$orderby=${pageData.sortField}${pageData.sortOrder}`;
    }
    if (pageData.filter !== '') {
      filter = `$filter=type eq '${pageData.filter}'`;
    }
    return this.http.get<any>(
      environment.baseApiUrl +
        `api/master_lookup?${skip}&${top}${orderBy}${filter}`,
    );
  }

  dataMasterLookupAdd(masterlookup): Observable<any> {
    return this.http.post<any>(
      environment.baseApiUrl + `api/master_lookup/`,
      masterlookup,
    );
  }

  isNameExists(duplicationCheck): Observable<any> {
    return this.http.post<boolean>(
      environment.baseApiUrl + `api/master_lookup/duplication/`,
      duplicationCheck,
    );
  }

  isTypeExists(locationCheck: any): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8',
    );
    return this.http.post<any>(
      environment.baseApiUrl + 'api/master_lookup/type/',
      locationCheck,
      { headers: headers },
    );
  }

  dataMasterLookupEdit(id, masterlookup): Observable<any> {
    return this.http.put<any>(
      environment.baseApiUrl + `api/master_lookup/${id}`,
      masterlookup,
    );
  }

  dataDropDownTypes(): Observable<any> {
    return this.http.get<any>(
      environment.baseApiUrl + 'api/master_lookup?$select=type',
    );
  }

  addTranslation(id, data: Translation): Observable<any> {
    return this.http.post<any>(
      `${environment.baseApiUrl}api/master_lookup/translation/${id}`,
      data,
    );
  }
}
