import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  api_base = environment.baseApiUrl;

  constructor(private http: HttpClient) {}

  download(
    path,
    fileName: string,
    extension = '.xlsx',
    postData = null,
    callback = null,
  ) {
    let obs: Observable<any> = null;

    const re = /(?:\.([^.]+))?$/;
    extension = re.exec(fileName)[1];
    path = `${this.api_base}/${path}`;
    if (postData) {
      obs = this.http.post(path, postData, { responseType: 'arraybuffer' });
    } else {
      obs = this.http.get(path, { responseType: 'arraybuffer' });
    }

    obs.subscribe(
      data => {
        this.createAndDownloadBlobFile(data, fileName, extension);
        if (callback) {
          callback();
        }
      },
      err => {},
    );
  }

  private createAndDownloadBlobFile(body, filename, extension = 'pdf') {
    const blob = new Blob([body]);
    const fileName = `${filename}`;
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
