import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { Observable } from 'rxjs';
import { Pageable } from '../models/pageable';
import { UserToken } from '../models/user-token.model';

@Injectable({
  providedIn: 'root',
})
@Injectable()
export class UserService {
  key: string;

  constructor(
    private http: HttpClient, // private _commonService: CommonService,
  ) {}

  GetUsers(): Observable<any> {
    return this.http.get<any>(
      environment.baseApiUrl +
        'api/User?$select=id,status,first_name_1,first_name_2,last_name,created_date,applicationuser',
    );
  }

  EmergencySet(user: any): Observable<any> {
    return this.http.post<any>(
      environment.baseApiUrl + 'api/User/emergencySet',
      user,
    );
  }

  GetAddress(): Observable<any> {
    return this.http.get<any>(environment.baseApiUrl + 'api/ApplicationUsers');
  }

  GetRoles(): Observable<any> {
    return this.http.get<any>(environment.baseApiUrl + 'api/User/Roles');
  }

  GetUserRole(): Observable<any> {
    return this.http.get<any>(environment.baseApiUrl + 'api/User/UserRole');
  }

  confirmPassword(passwordBindingModel): Observable<any> {
    /*return this.http.post<boolean>(
      environment.baseApiUrl + 'api/User/ConfirmPassword',
      passwordBindingModel,
    );*/
    return null;
  }

  addUser(user): Promise<any> {
    return this.http
      .post<boolean>(environment.baseApiUrl + 'api/User', user)
      .toPromise();
  }

  editUser(id, user): Promise<any> {
    return this.http
      .put<boolean>(environment.baseApiUrl + `api/User/${id}`, user)
      .toPromise();
  }

  EmailExists(key): Observable<any> {
    return this.http.get<any>(
      environment.baseApiUrl + 'api/User/Email/' + key + '/',
    );
  }
  LogonExists(key): Observable<any> {
    return this.http.get<any>(
      environment.baseApiUrl + 'api/User/Logon/' + key + '/',
    );
  }

  GetPaginatedUsers(pageableData: Pageable): Observable<any> {
    let filters: string = '$filter=';
    let filter: string;
    let orderBy: string;
    filter = '';
    pageableData.filters.forEach(x => {
      // check STATUS dropdown control filtrations
      if (x.fieldName === 'status_label') {
        if (filter.length > 1) {
          filter += ' and ';
        }
        filter += '(';
        if (x.fieldValue.includes(',') && x.fieldValue.length > 1) {
          const attributeList = x.fieldValue.split(',');

          attributeList.forEach(element => {
            if (element === 'Active') {
              filter += `status eq true or `;
            } else {
              filter += `status eq false or `;
            }
          });
          filter = filter.slice(0, -4);
        } else {
          if (x.fieldValue === 'Active') {
            filter += `status eq true`;
          } else {
            filter += `status eq false`;
          }
        }
        filter += ')';
      }

      // check USER_PROFILE dropdown control filtrations
      if (x.fieldName === 'user_profile_id') {
        if (filter.length > 1) {
          filter += ' and ';
        }
        filter += '(';
        if (x.fieldValue.includes(',') && x.fieldValue.length > 1) {
          const attributeList = x.fieldValue.split(',');
          attributeList.forEach(element => {
            filter += `user_profile_id eq '${element}' or `;
          });
          filter = filter.slice(0, -4);
        } else {
          filter += `user_profile_id eq '${x.fieldValue}'`;
        }
        filter += ')';
      }

      // check TITLE dropdown control filtrations
      if (x.fieldName === 'title') {
        if (filter.length > 1) {
          filter += ' and ';
        }
        filter += '(';
        if (x.fieldValue.includes(',')) {
          const attributeList = x.fieldValue.split(',');
          attributeList.forEach(element => {
            filter += `substringof('${element}',title) eq true or `;
          });
          filter = filter.slice(0, -4);
        } else {
          filter += `substringof('${x.fieldValue}',title) eq true`;
        }
        filter += ')';
      }
      // check LAST_NAME dropdown control filtrations
      if (x.fieldName === 'last_name') {
        if (filter.length > 1) {
          filter += ' and ';
        }
        filter += '(';
        if (x.fieldValue.includes(',') && x.fieldValue.length > 1) {
          const attributeList = x.fieldValue.split(',');
          attributeList.forEach(element => {
            filter += `substringof('${element}',last_name) eq true or `;
          });
          filter = filter.slice(0, -4);
        } else {
          filter += `substringof('${x.fieldValue}',last_name) eq true`;
        }
        filter += ')';
      }

      // check FIRST_NAME1 dropdown control filtrations
      if (x.fieldName === 'first_name_1') {
        if (filter.length > 1) {
          filter += ' and ';
        }
        filter += '(';
        if (x.fieldValue.includes(',') && x.fieldValue.length > 1) {
          const attributeList = x.fieldValue.split(',');
          attributeList.forEach(element => {
            filter += `substringof('${element}',first_name_1) eq true or `;
          });
          filter = filter.slice(0, -4);
        } else {
          filter += `substringof('${x.fieldValue}',first_name_1) eq true`;
        }
        filter += ')';
      }

      // check FIRST_NAME2 dropdown control filtrations
      if (x.fieldName === 'first_name_2') {
        if (filter.length > 1) {
          filter += ' and ';
        }
        filter += '(';
        if (x.fieldValue.includes(',') && x.fieldValue.length > 1) {
          const attributeList = x.fieldValue.split(',');
          attributeList.forEach(element => {
            filter += `substringof('${element}',first_name_2) eq true or `;
          });
          filter = filter.slice(0, -4);
        } else {
          filter += `substringof('${x.fieldValue}',first_name_2) eq true`;
        }
        filter += ')';
      }

      // check EMAIL_ADDRESS dropdown control filtrations
      if (x.fieldName === 'email_address') {
        if (filter.length > 1) {
          filter += ' and ';
        }
        filter += '(';
        if (x.fieldValue.includes(',') && x.fieldValue.length > 1) {
          const attributeList = x.fieldValue.split(',');
          attributeList.forEach(element => {
            filter += `application_user_id eq '${element}' or `;
          });
          filter = filter.slice(0, -4);
        } else {
          filter += `application_user_id eq '${x.fieldValue}'`;
        }
        filter += ')';
      }
    });

    if (
      pageableData.sortField !== undefined ||
      pageableData.sortField !== null
    ) {
      orderBy = `$orderby=${pageableData.sortField}${pageableData.sortOrder}`;
    }

    filters += filter;
    if (filter === '') filters = '';

    const skip = `$skip=${pageableData.page}`;
    const top = `$top=${pageableData.size}`;

    // console.log('url', `api/user?${skip}&${top}${orderBy}${filters}`);
    return this.http.get<any>(
      environment.baseApiUrl + `api/User?${skip}&${top}${orderBy}${filters}`,
    );
  }

  resetPassword(data: any) {
    return this.http.post<any>(
      `${environment.baseApiUrl}api/User/ResetPassword`,
      data,
    );
  }

  verifyToken(token: UserToken) {
    return this.http.post<any>(
      `${environment.baseApiUrl}api/User/verify-token`,
      token,
    );
  }
}
