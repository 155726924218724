export const environment = {
  production: false,
  qa: true,
  sit: false,
  dev: false,
  baseApiUrl: 'https://carbonregistryqaapi.worldbank.org/',
  appUrl: 'https://carbonregistryqa.worldbank.org/',

  azure: {
    authority:
      'https://login.microsoftonline.com/31a2fec0-266b-4c67-b56e-2796d8f59c36/',
    tokenEndpoint:
      'https://login.microsoftonline.com/31a2fec0-266b-4c67-b56e-2796d8f59c36/oauth2/v2.0/token',
    codeEndpoint:
      'https://login.microsoftonline.com/31a2fec0-266b-4c67-b56e-2796d8f59c36/oauth2/v2.0/authorize',
    clientId: 'a1e68376-3851-4706-8c47-c387c2b3ad91', // fill the azure client id for the front end app here
    redirectUri: 'https://carbonregistryqa.worldbank.org/',
    //scope: 'https://worldbankgroup.onmicrosoft.com/DEV_wb-carbonreg_WebAPI/a1f5356c-b364-4b58-ad51-b3f6fe349d76/user_impersonation',
    scope:
      'https://worldbankgroup.onmicrosoft.com/QA_wb-carbonreg_WebAPI/92582fd1-4b71-424d-9e5c-a71a7f9276d4/user_impersonation',
  },
};
