import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import {
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbThemeModule,
  NbWindowModule,
  NbCardModule,
  NbIconModule,
  NbAlertModule,
  NbInputModule,
  NbSpinnerModule,
  NbSelectModule,
} from '@nebular/theme';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { FormsModule } from '@angular/forms';
import { UserService } from '../services/user.service';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { LanguageSelectionComponent } from './components/language-selection/language-selection.component';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RoleProvider } from './services/role.provider';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { DateFormatPipe } from './pipes/date-format.pipe';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    LoginDialogComponent,
    LanguageSelectionComponent,
    MessageDialogComponent,
    DateFormatPipe,
  ],
  imports: [
    CommonModule,
    NbDialogModule,
    NbMenuModule,
    NbSidebarModule,
    NbToastrModule,
    NbWindowModule,
    NbThemeModule,
    NbCardModule,
    NbIconModule,
    NbThemeModule,
    NbAlertModule,
    NbInputModule,
    FormsModule,
    NbSpinnerModule,
    NbSelectModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [UserService, RoleProvider, TranslateService],
  entryComponents: [
    ConfirmationDialogComponent,
    MessageDialogComponent,
    LoginDialogComponent,
    LanguageSelectionComponent,
  ],
  exports: [LanguageSelectionComponent, DateFormatPipe],
})
export class AppSharedModule {}
