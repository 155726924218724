import { Injectable } from '@angular/core';
import {
  NbTokenStorage,
  NbAuthTokenParceler,
  NbAuthToken,
} from '@nebular/auth';

@Injectable()
export class NbTokenSessionStorage extends NbTokenStorage {
  protected key = 'auth_app_token';

  constructor(private parceler: NbAuthTokenParceler) {
    super();
  }

  /**
   * Returns token from sessionStorage
   * @returns {NbAuthToken}
   */
  get(): NbAuthToken {
    const raw = sessionStorage.getItem(this.key);
    return this.parceler.unwrap(raw);
  }

  /**
   * Sets token to sessionStorage
   * @param {NbAuthToken} token
   */
  set(token: NbAuthToken) {
    const raw = this.parceler.wrap(token);
    sessionStorage.setItem(this.key, raw);
  }

  /**
   * Clears token from sessionStorage
   */
  clear() {
    sessionStorage.removeItem(this.key);
  }
}
