<nb-card>
  <nb-card-header>{{ title }}</nb-card-header>
  <nb-card-body>{{ body }}</nb-card-body>
  <nb-card-footer>
    <button class="btn btn-secondary" nbButton (click)="close(false)">
      {{ 'form.button.close' | translate }}
    </button>
    <button class="btn btn-primary" nbButton (click)="close(true)">
      {{ 'form.button.' + buttonLabel | translate }}
    </button>
  </nb-card-footer>
</nb-card>
