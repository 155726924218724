import {
  Component,
  ChangeDetectorRef,
  Inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  NbAuthService,
  getDeepFromObject,
  // NbAuthResult,
  NB_AUTH_OPTIONS,
} from '@nebular/auth';
import { Router } from '@angular/router'; // , ActivatedRoute
import { NbDialogRef } from '@nebular/theme';
import { UserService } from '../../../services/user.service';
import { PasswordBindingModel } from '../../../models/password-binding-model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginDialogComponent {
  user: any = {};
  showMessages: any = {};
  strategy: string = '';
  translations: any;
  errors: string[] = [];
  messages: string[] = [];
  errorMsg: string = '';
  submitted: boolean = false;
  passwordBindingModel: PasswordBindingModel;
  public isLoading: boolean = false;
  constructor(
    protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected router: Router,
    protected translateService: TranslateService,
    protected ref: NbDialogRef<LoginDialogComponent>,
    private _userService: UserService,
  ) {
    this.showMessages = this.getConfigValue('forms.login.showMessages');
    this.loadTranslations();
  }

  loadTranslations() {
    this.translateService
      .stream([
        'form.validation.passwordIncorrect',
        'form.validation.passwordMinMax',
      ])
      .subscribe(res => {
        this.translations = res;
      });
  }

  close(value: boolean) {
    this.ref.close(false);
  }
  login(): void {
    this.messages = [];
    this.submitted = true;
    this.passwordBindingModel = new PasswordBindingModel();
    this.passwordBindingModel.Password = encodeURIComponent(this.user.password);
    this.isLoading = true;
    this._userService.confirmPassword(this.passwordBindingModel).subscribe(
      data => {
        this.submitted = false;
        if (data === true) {
          this.ref.close(data);
        } else {
          this.errors = [];
          this.errors.push(
            this.translations['form.validation.passwordIncorrect'],
          );
        }
        this.isLoading = false;
        if (!this.cd['destroyed']) {
          this.cd.detectChanges();
        }
      },
      err => {
        this.isLoading = false;
        this.submitted = false;
        this.errors = [];
        this.errors.push(
          this.translations['form.validation.passwordIncorrect'],
        );
        if (!this.cd['destroyed']) {
          this.cd.detectChanges();
        }
      },
    );
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  get passwordLengthMsg(): string {
    const msg: string = this.translations['form.validation.passwordMinMax'];
    const min = this.getConfigValue('forms.validation.password.minLength');
    const max = this.getConfigValue('forms.validation.password.maxLength');
    return msg.replace('{0}', min).replace('{1}', max);
  }
}
