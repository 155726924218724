import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { isNullOrUndefined } from 'util';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  readonly home = {
    icon: 'pi pi-home',
    routerLink: '/pages/dashboard',
    label: 'Home',
  };
  menuItems: MenuItem[];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.menuItems = this.createBreadcrumbs(this.activatedRoute.snapshot);
      });

    // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //   this.menuItems = this.createBreadcrumbs(this.activatedRoute.root);
    // });
  }

  private createBreadcrumbs(
    route: ActivatedRouteSnapshot,
    url: string = '',
    breadcrumbs: MenuItem[] = [],
  ): MenuItem[] {
    const children: ActivatedRouteSnapshot[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }
      if (url.toLowerCase().indexOf('pages') === -1) url = '/pages' + url;
      const label = child.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB];
      if (!isNullOrUndefined(label)) {
        const translatedLabel = this.translate.instant(label);

        breadcrumbs.push({ label: translatedLabel, routerLink: url });
      }
      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }
}
