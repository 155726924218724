import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NbLayoutDirectionService, NbLayoutDirection } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-language-selection',
  templateUrl: './language-selection.component.html',
  styleUrls: ['./language-selection.component.scss'],
})
export class LanguageSelectionComponent implements OnInit {
  public languages: Array<{ key: string; caption: string }> = [];

  selectedItem;
  currentDirection: NbLayoutDirection;
  directions = NbLayoutDirection;
  alive = true;

  constructor(
    private translateService: TranslateService,
    private directionService: NbLayoutDirectionService,
    private route: ActivatedRoute,
  ) {
    this.currentDirection = this.directionService.getDirection();

    this.directionService
      .onDirectionChange()
      .pipe(takeWhile(() => this.alive))
      .subscribe(newDirection => (this.currentDirection = newDirection));
  }

  public ngOnInit(): void {
    this.selectedItem = this.translateService.getDefaultLang();
    this.loadLanguages();
    const locale = localStorage.getItem('registry.locale');
    this.route.queryParams.subscribe(params => {
      const lang = params['locale'];
      if (lang) {
        this.setLanguage(lang);
      } else if (locale) {
        this.setLanguage(locale);
      } else {
        const browserLang = this.translateService.getBrowserLang();
        this.setLanguage(browserLang.match(/en|ar/) ? browserLang : 'en');
      }
    });
  }

  public use(language: string): void {
    if (language !== localStorage.getItem('registry.locale')) {
      localStorage.setItem('registry.locale', language);
      const languages = JSON.parse(
        window.localStorage.getItem('registry.languages'),
      );
      if (languages) {
        const langId = languages.find(x => x.value === language);
        if (language) {
          window.localStorage.setItem('registry.localeId', langId.id);
        }
      }
      window.location.reload();
      return;
    }
  }

  setLanguage(language) {
    this.selectedItem = language;
    this.translateService.use(language);
    this.loadLanguages();
    if (language === 'ar')
      this.directionService.setDirection(this.directions.RTL);
    else this.directionService.setDirection(this.directions.LTR);
    localStorage.setItem('registry.locale', language);
  }

  loadLanguages() {
    const languageKeys: Array<string> = this.translateService.getLangs();
    if (languageKeys && languageKeys.length) {
      this.languages.length = 0;
      languageKeys.forEach(languageKey => {
        this.translateService
          .get(`app.languages.${languageKey}`)
          .subscribe(languageCaption =>
            this.languages.push({
              key: languageKey,
              caption: languageCaption,
            }),
          );
      });
    }
  }
}
