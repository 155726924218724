<nb-card>
  <nb-card-header>{{
    'form.label.confirmPassword' | translate
  }}</nb-card-header>

  <nb-card-body>
    <nb-alert
      *ngIf="errors.length > 0 && !submitted"
      outline="danger"
      role="alert"
    >
      <p class="alert-title"><b></b></p>
      <ul class="alert-message-list">
        <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
      </ul>
    </nb-alert>

    <form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">
      <div class="form-control-group">
        <input
          nbInput
          fullWidth
          [(ngModel)]="user.password"
          #password="ngModel"
          name="password"
          type="password"
          id="input-password"
          placeholder="{{ 'form.label.password' | translate }}"
          [status]="
            password.dirty ? (password.invalid ? 'danger' : 'success') : ''
          "
          [required]="getConfigValue('forms.validation.password.required')"
          [minlength]="getConfigValue('forms.validation.password.minLength')"
          [maxlength]="getConfigValue('forms.validation.password.maxLength')"
          [attr.aria-invalid]="
            password.invalid && password.touched ? true : null
          "
          class="mb-5"
        />
        <div *ngIf="password.invalid && password.touched">
          <!-- <ng-container > -->
          <div class="error-message" *ngIf="password.errors?.required">
            {{ 'form.validation.password' | translate }}
          </div>
          <div
            class="error-message"
            *ngIf="password.errors?.minlength || password.errors?.maxlength"
          >
            {{ passwordLengthMsg }}
          </div>
          <!-- </ng-container> -->
        </div>
      </div>
      <div class="btn-div">
        <button class="btn btn-primary" nbButton [class.btn-pulse]="submitted">
          {{ 'form.button.confirm' | translate }}
        </button>
        <button class="btn btn-secondary" nbButton (click)="close(false)">
          {{ 'form.button.close' | translate }}
        </button>
      </div>
    </form>
  </nb-card-body>
</nb-card>
