<nb-select
  size="tiny"
  status="basic"
  [(selected)]="selectedItem"
  (selectedChange)="use($event)"
>
  <nb-option *ngFor="let language of languages" [value]="language.key">{{
    language.caption
  }}</nb-option>
</nb-select>
