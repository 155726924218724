import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  title: string = 'default title';
  body: string = 'default body';
  buttonLabel: string = 'confirm';

  constructor(protected ref: NbDialogRef<ConfirmationDialogComponent>) {}

  ngOnInit() {}

  close(value: boolean) {
    this.ref.close(value);
  }
}
