import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';

import { map, takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { RegistryService } from '../../../services/registry.service';
import { Registry } from '../../../models/registry';
import { SystemUser } from '../../../models/system-user';
import { TranslateService } from '@ngx-translate/core';
import { RoleProvider } from '../../../shared/services/role.provider';
import { environment } from '../../../../environments/environment';
import { MsalService } from '../../../msal';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: SystemUser;
  logoName: string = 'logo.png';

  roles: any[];

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [{ title: 'Log out', link: '/auth/logout' }];
  languages = [];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService,
    //private authService: NbAuthService,
    private authService: MsalService,
    private registryService: RegistryService,
    public translate: TranslateService,
    private nbMenuService: NbMenuService,
    private roleProvider: RoleProvider,
    private _userService: UserService,
  ) {}

  ngOnInit() {
    if (environment.baseApiUrl.indexOf('lk/') !== -1) {
      this.logoName = 'logo_LK.png';
    }
    this.currentTheme = this.themeService.currentTheme;
    this.user = {} as SystemUser;
    this.user.userPayload = {} as any;
    this.user.currentRegistry = {} as Registry;
    /*this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.user.userPayload = token.getPayload();
      }
    });*/
    /*
    if (
      localStorage.getItem('carbonregrole') == null ||
      localStorage.getItem('carbonregrole') == undefined
    ) {
      this._userService.GetUserRole().subscribe(
        result => {
          if (result !== null) {
            console.log('header' + result[0].user_profile_id);
            localStorage.setItem('carbonregrole', result[0].user_profile_id);
            this.user.userPayload.email = localStorage.getItem(
              'carbonregemail',
            );
          }
          // return 'guest';
        },
        err => {
          console.log(err.message);
        },
      );
    } else {
      this.user.userPayload.email = localStorage.getItem('carbonregemail');
    } */

    this.user.userPayload.email = localStorage.getItem('carbonregemail');
    if(localStorage.getItem('carbonregrole') != null && localStorage.getItem('carbonregrole') != undefined) {
      this.registryService.getCurrentRegistry().then(data => {
        if (data) {
          this.user.currentRegistry = {
            name: data.name,
            id: data.Id,
            location_content: data.location_content,
            is_current: data.is_current,
            code: data.code,
            language_content: data.language_content,
            description: data.description,
            active: data.active,
            created_on: data.created_on,
            created_by: data.created_by,
            certificate_ext: data.certificate_ext,
            certificate_name: data.certificate_name,
            certificate_content_data: data.certificate_content_data,
            user_image: data.user_image,
          };
          window.localStorage.setItem(
            'registry.languages',
            JSON.stringify(data.languages),
          );
          this.roleProvider.setLanguageId();
        }
      });
    }
    

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl),
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => (this.currentTheme = themeName));

    this.handleLanguages();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  handleLanguages() {
    this.languages = this.translate.getLangs().map(val => {
      return { title: val.toUpperCase() };
    });

    this.nbMenuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'languages'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => this.translate.use(title));
  }
}
