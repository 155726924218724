import { Injectable } from '@angular/core';
import { filter, map, takeUntil } from 'rxjs/operators';

import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { NbRoleProvider } from '@nebular/security';
import { UserService } from '../../services/user.service';
//import { Observable } from 'rxjs/internal/Observable';
import { Observable, Subject } from 'rxjs/Rx';
import { of } from 'rxjs/internal/observable/of';
import { MsalBroadcastService, MsalService } from '../../msal';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';

@Injectable()
export class RoleProvider implements NbRoleProvider {
  private readonly _destroying$ = new Subject<void>();
  constructor(
    //private authService: NbAuthService,
    private authService: MsalService,
    private _userService: UserService,    
  ) {        
  }

  languageId;

  role: Observable<string>;
  emprole: string;

  /*getRole() {
    return this.authService.onTokenChange().pipe(
      map((token: NbAuthJWTToken) => {
        return token.isValid() ? token.getPayload()['role'] : 'RegistryAdmin';
      }),
    );
  }*/

  getRole() {
    return this._userService
      .GetUserRole()
      .pipe(map(user => user!= undefined ? user[0].user_profile_id : null));
  }

  setLanguageId() {
    const languages = JSON.parse(
      window.localStorage.getItem('registry.languages'),
    );
    const locale = window.localStorage.getItem('registry.locale');
    if (locale) {
      const language = languages.find(x => x.value === locale);
      if (language) {
        window.localStorage.setItem('registry.localeId', language.id);
      }
    }
  }

  getLanguageId() {
    if (this.languageId) {
      return this.languageId;
    } else {
      const localeId = window.localStorage.getItem('registry.localeId');
      if (localeId) {
        this.languageId = localeId;
        return localeId;
      }
    }
  }
}
